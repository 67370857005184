import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as LogoutIcon } from '../svgs/popuplogout.svg';
import { ReactComponent as X } from '../svgs/X.svg';

const LogoutPopup = ({ isOpen, onClose, onLogout }) => {
  
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mouseup', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 backdrop-blur-md">
      <div ref={popupRef} className="bg-white-bg rounded-5xl w-1/4 h-1/3 p-3 relative flex flex-row">
        <div className="w-5/6 flex flex-col items-start justify-end">
          <div className="text-3xl mb-2 leading-none">Deseja Sair?</div>
          <p className="text-xs text-gray-mid-dark1<+">Tem a certeza que pretende sair da sua conta?</p>
          <div className="flex justify-start mt-12 w-full">
            <button className="mr-1 px-8 py-3.5 rounded-full w-1/2 bg-cyan-uro text-black text-xs transition-colors duration-300 ease-in-out hover:bg-black hover:text-white" onClick={onLogout}>Sair</button>
            <button className="px-3.5 py-3 rounded-full bg-gray-mid transition-colors duration-300 ease-in-out hover:bg-black" onClick={onClose}>
              <X className="w-4 h-2.5 text-white" />
            </button>
          </div>
        </div>
        <div className="w-1/6 flex items-start justify-end">
          <LogoutIcon className="w-14 h-14" />
        </div>
      </div>
    </div>
  );
};

LogoutPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default LogoutPopup;
