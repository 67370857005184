import React, { useState } from 'react';
import axios from '../axios';
import PropTypes from 'prop-types';
import '../styles/clinicalData.css';
import { ReactComponent as IPSSIcon } from '../svgs/clinicalData/ipss.svg';
import { ReactComponent as PSATIcon } from '../svgs/clinicalData/psat.svg';
import { ReactComponent as PSALTIcon } from '../svgs/clinicalData/psalt.svg';
import { ReactComponent as VolumeIcon } from '../svgs/clinicalData/volume.svg';
import { ReactComponent as LoboIcon } from '../svgs/clinicalData/lobo.svg';
import { ReactComponent as PiradsIcon } from '../svgs/clinicalData/pirads.svg';
import { ReactComponent as QmaxIcon } from '../svgs/clinicalData/qmax.svg';
import { ReactComponent as ResidueIcon } from '../svgs/clinicalData/residue.svg';
import { ReactComponent as Plus } from '../svgs/plus.svg';


const ClinicalData = ({ patientId, birthdate, showAlertWithMessage }) => {

  const today = new Date().toISOString().split('T')[0];
  const [clinicalData, setClinicalData] = useState({
    ipssValue: '',
    ipssDate: '',
    psatValue: '',
    psatDate: '',
    psaltValue: '',
    psaltDate: '',
    volumeValue: '',
    volumeDate: '',
    loboValue: '',
    loboDate: '',
    piradsValue: '',
    piradsDate: '',
    qmaxValue: '',
    qmaxDate: '',
    residueValue: '',
    residueDate: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name.endsWith('Date')) {
      const inputDate = new Date(value);
      const year = inputDate.getFullYear();
      const month = inputDate.getMonth() + 1; 
      const day = inputDate.getDate();
  
      const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      
      if (new Date(formattedDate) <= birthdate) {
        showAlertWithMessage(`A data deve ser posterior a ${birthdate}.`, true);
        return;
      }
    }
  
    setClinicalData(prevState => ({...prevState, [name]: value }));
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const requiredPairs = [
      ['ipssValue', 'ipssDate'],
      ['psatValue', 'psatDate'],
      ['psaltValue', 'psaltDate'],
      ['volumeValue', 'volumeDate'],
      ['loboValue', 'loboDate'],
      ['piradsValue', 'piradsDate'],
      ['qmaxValue', 'qmaxDate'],
      ['residueValue', 'residueDate'],
    ];

    for (let [valueKey, dateKey] of requiredPairs) {
      if (clinicalData[valueKey] && !clinicalData[dateKey]) {
        showAlertWithMessage(`Data de um dos dados em falta.`, true);
        return;
      }
    }

    try {
      await axios.post(`/clinical/${patientId}`, clinicalData);
      showAlertWithMessage('Dados adicionados com sucesso!', false);

      setClinicalData({
        ipssValue: '',
        ipssDate: '',
        psatValue: '',
        psatDate: '',
        psaltValue: '',
        psaltDate: '',
        volumeValue: '',
        volumeDate: '',
        loboValue: '',
        loboDate: '',
        piradsValue: '',
        piradsDate: '',
        qmaxValue: '',
        qmaxDate: '',
        residueValue: '',
        residueDate: ''
      });

    } catch (error) {
      console.error('Failed to add clinical data:', error);
      showAlertWithMessage('Erro ao adicionar dados.', true);
    }
  };

  return (
    <div className='h-full bg-white rounded-3xl p-5 pr-4 overflow-hidden'>
      <div className="flex items-center justify-between relative">
        <h2 className="text-4xl font-normal">Dados Clínicos</h2>
      </div>
      <form className="mt-4 h-9/10 relative" onSubmit={handleSubmit}>
        <div className="scroll-bar max-h-9/10 h-[80%] overflow-y-auto pr-1 pb-10 relative">
          <div className="relative z-20">
            <IPSSIcon className="w-12 h-12 p-3.5 absolute mt-0.75 ml-1 rounded-full" style={{ backgroundColor: "#F2FFD0" }} />
            <input type="number" id="ipssValue" name="ipssValue" min="0" max="35" onChange={handleChange} value={clinicalData.ipssValue} placeholder=''
              className="block rounded-full pl-16 pr-4 pb-1 pt-5 w-full h-13.5 
              text-sm text-gray-mid-dark 
              bg-cream-input
              appearance-none
              hover:cursor-pointer
              focus:outline-none focus:bg-cream-focus peer"
            />
            <label
              htmlFor="ipssValue"
              className="absolute text-base text-black
                duration-300 transform -translate-y-3 
                scale-90 top-4 z-10 origin-[0] start-16 
                hover:cursor-pointer
                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-3 rtl:peer-focus:translate-x-12 rtl:peer-focus:left-auto"
            >
              IPSS Total
            </label>

          </div>
          {(clinicalData.ipssValue || clinicalData.ipssDate) && (
            <div className='bg-gray-bg p-t-2 px-4 pt-10 rounded-b-4xl rounded-t-md mt-[-32px] z-10 slideDownDiv'>
              <div className='relative text-white'>
                <label className="text-white font-light absolute top-1 left-2">Data de recolha da amostra</label>
                <input type="date" name="ipssDate" onChange={handleChange} max={today} placeholder='dd/mm/yyyy'
                  className="date-input-white block w-full h-13.5 px-2 pb-1 pt-5 bg-transparent rounded-2xl text-sm font-light
                  hover:border-0 hover:text-white hover:cursor-pointer
                  focus:outline-none focus:border-0"
                />
              </div>
            </div>
          )}

          <div className="mt-2 relative z-20">
            <PSATIcon className="w-12 h-12 p-3.5 absolute mt-0.75 ml-1 rounded-full" style={{ backgroundColor: "#FFD7E6" }} />
            <input type="number" id="psatValue" name="psatValue" min="0" max="20" onChange={handleChange} value={clinicalData.psatValue} placeholder=''
              className="block rounded-full pl-16 pr-4 pb-1 pt-5 w-full h-13.5 
              text-sm text-gray-mid-dark 
              bg-cream-input
              appearance-none
              hover:cursor-pointer 
              focus:outline-none focus:bg-cream-focus peer"
            />
            <label
              htmlFor="psatValue"
              className="absolute text-base text-black
                duration-300 transform -translate-y-3 
                scale-90 top-4 z-10 origin-[0] start-16 
                hover:cursor-pointer
                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-3 rtl:peer-focus:translate-x-12 rtl:peer-focus:left-auto"
            >
              PSA Total <span className="text-base text-gray-500">[ng/ml]</span>
            </label>
          </div>
          {(clinicalData.psatValue || clinicalData.psatDate) && (
            <div className='bg-gray-bg p-t-2 px-4 pt-10 rounded-b-4xl rounded-t-md mt-[-32px] z-10 slideDownDiv'>
            <div className='relative text-white'>
              <label className="text-white font-light absolute top-1 left-2">Data de recolha da amostra</label>
              <input type="date" name="psatDate" onChange={handleChange} max={today} placeholder='dd/mm/yyyy'
                className="date-input-white block w-full h-13.5 px-2 pb-1 pt-5 bg-transparent rounded-2xl text-sm font-light
                hover:border-0 hover:text-white hover:cursor-pointer
                focus:outline-none focus:border-0"
              />
            </div>
          </div>
          )}

          <div className="mt-2 relative z-20">
            <PSALTIcon className="w-12 h-12 p-3.5 absolute mt-0.75 ml-1 rounded-full" style={{ backgroundColor: "#FFFFFF" }} />
            <input type="number" id="psaltValue" name="psaltValue" min="0" max="50" onChange={handleChange} value={clinicalData.psaltValue} placeholder=''
              className="block rounded-full pl-16 pr-4 pb-1 pt-5 w-full h-13.5 
              text-sm text-gray-mid-dark 
              bg-cream-input
              appearance-none 
              hover:cursor-pointer
              focus:outline-none focus:bg-cream-focus peer"
            />
            <label
              htmlFor="psaltValue"
              className="absolute text-base text-black
                duration-300 transform -translate-y-3 
                scale-90 top-4 z-10 origin-[0] start-16
                hover:cursor-pointer 
                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-3 rtl:peer-focus:translate-x-12 rtl:peer-focus:left-auto"
            >
              PSA Livre/PSA Total <span className="text-base text-gray-500">[%]</span>
            </label>
          </div>
          {(clinicalData.psaltValue || clinicalData.psaltDate) && (
            <div className='bg-gray-bg p-t-2 px-4 pt-10 rounded-b-4xl rounded-t-md mt-[-32px] z-10 slideDownDiv'>
            <div className='relative text-white'>
              <label className="text-white font-light absolute top-1 left-2">Data de recolha da amostra</label>
              <input type="date" name="psaltDate" onChange={handleChange} max={today} placeholder='dd/mm/yyyy'
                className="date-input-white block w-full h-13.5 px-2 pb-1 pt-5 bg-transparent rounded-2xl text-sm font-light
                hover:border-0 hover:text-white hover:cursor-pointer
                focus:outline-none focus:border-0"
              />
            </div>
          </div>
          )}

          <div className="mt-2 relative z-20">
            <VolumeIcon className="w-12 h-12 p-3.5 absolute mt-0.75 ml-1 rounded-full" style={{ backgroundColor: "#C7C7FF" }} />
            <input type="number" id="volumeValue" name="volumeValue" min="20" max="250" onChange={handleChange}value={clinicalData.volumeValue}  placeholder=''
              className="block rounded-full pl-16 pr-4 pb-1 pt-5 w-full h-13.5 
              text-sm text-gray-mid-dark 
              bg-cream-input
              appearance-none 
              hover:cursor-pointer
              focus:outline-none focus:bg-cream-focus peer"
            />
            <label
              htmlFor="volumeValue"
              className="absolute text-base text-black
                duration-300 transform -translate-y-3 
                scale-90 top-4 z-10 origin-[0] start-16
                hover:cursor-pointer 
                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-3 rtl:peer-focus:translate-x-12 rtl:peer-focus:left-auto"
            >
              Volume da Próstata <span className="text-base text-gray-500">[cc]</span>
            </label>
          </div>
          {(clinicalData.volumeValue || clinicalData.volumeDate) && (
            <div className='bg-gray-bg p-t-2 px-4 pt-10 rounded-b-4xl rounded-t-md mt-[-32px] z-10 slideDownDiv'>
            <div className='relative text-white'>
              <label className="text-white font-light absolute top-1 left-2">Data de recolha da amostra</label>
              <input type="date" name="volumeDate" onChange={handleChange} max={today} placeholder='dd/mm/yyyy'
                className="date-input-white block w-full h-13.5 px-2 pb-1 pt-5 bg-transparent rounded-2xl text-sm font-light
                hover:border-0 hover:text-white hover:cursor-pointer
                focus:outline-none focus:border-0"
              />
            </div>
          </div>
          )}

          <div className="mt-2 relative z-20">
            <LoboIcon className="w-12 h-12 p-3.5 absolute mt-0.75 ml-1 rounded-full" style={{ backgroundColor: "#D6F6FC" }} />
            <input type="number" id="loboValue" name="loboValue" step="0.1" min="0" max="2.5" onChange={handleChange} value={clinicalData.loboValue} placeholder=''
              className="block rounded-full pl-16 pr-4 pb-1 pt-5 w-full h-13.5 
              text-sm text-gray-mid-dark 
              bg-cream-input
              appearance-none 
              hover:cursor-pointer
              focus:outline-none focus:bg-cream-focus peer"
            />
            <label
              htmlFor="loboValue"
              className="absolute text-base text-black
                duration-300 transform -translate-y-3 
                scale-75 top-4 z-10 origin-[0] start-16
                hover:cursor-pointer 
                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-3 rtl:peer-focus:translate-x-12 rtl:peer-focus:left-auto"
            >
              Lobo Médio <span className="text-base text-gray-500">[cm]</span>
            </label>
          </div>
          {(clinicalData.loboValue || clinicalData.loboDate) && (
            <div className='bg-gray-bg p-t-2 px-4 pt-10 rounded-b-4xl rounded-t-md mt-[-32px] z-10 slideDownDiv'>
            <div className='relative text-white'>
              <label className="text-white font-light absolute top-1 left-2">Data de recolha da amostra</label>
              <input type="date" name="loboDate" onChange={handleChange} max={today} placeholder='dd/mm/yyyy'
                className="date-input-white block w-full h-13.5 px-2 pb-1 pt-5 bg-transparent rounded-2xl text-sm font-light
                hover:border-0 hover:text-white hover:cursor-pointer
                focus:outline-none focus:border-0"
              />
            </div>
          </div>
          )}

          <div className="mt-2 relative z-20">
            <PiradsIcon className="w-12 h-12 p-3.5 absolute mt-0.75 ml-1 rounded-full" style={{ backgroundColor: "#FFF0C2" }} />
            <input type="number" id="piradsValue" name="piradsValue" min="1" max="5" onChange={handleChange} value={clinicalData.piradsValue} placeholder=''
              className="block rounded-full pl-16 pr-4 pb-1 pt-5 w-full h-13.5 
              text-sm text-gray-mid-dark 
              bg-cream-input
              appearance-none 
              hover:cursor-pointer
              focus:outline-none focus:bg-cream-focus peer"
            />
            <label
              htmlFor="piradsValue"
              className="absolute text-base text-black
                duration-300 transform -translate-y-3 
                scale-90 top-4 z-10 origin-[0] start-16
                hover:cursor-pointer 
                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-3 rtl:peer-focus:translate-x-12 rtl:peer-focus:left-auto"
            >
              PI-RADS
            </label>
          </div>
          {(clinicalData.piradsValue || clinicalData.piradsDate) && (
            <div className='bg-gray-bg p-t-2 px-4 pt-10 rounded-b-4xl rounded-t-md mt-[-32px] z-10 slideDownDiv'>
            <div className='relative text-white'>
              <label className="text-white font-light absolute top-1 left-2">Data de recolha da amostra</label>
              <input type="date" name="piradsDate" onChange={handleChange} max={today} placeholder='dd/mm/yyyy'
                className="date-input-white block w-full h-13.5 px-2 pb-1 pt-5 bg-transparent rounded-2xl text-sm font-light
                hover:border-0 hover:text-white hover:cursor-pointer
                focus:outline-none focus:border-0"
              />
            </div>
          </div>
          )}

          <div className="mt-2 relative z-20">
            <QmaxIcon className="w-12 h-12 p-3.5 absolute mt-0.75 ml-1 rounded-full" style={{ backgroundColor: "#FFEDE4" }} />
            <input type="number" id="qmaxValue" name="qmaxValue"  min="0" max="30" onChange={handleChange} value={clinicalData.qmaxValue} placeholder=''
              className="block rounded-full pl-16 pr-4 pb-1 pt-5 w-full h-13.5 
              text-sm text-gray-mid-dark 
              bg-cream-input
              appearance-none 
              hover:cursor-pointer
              focus:outline-none focus:bg-cream-focus peer"
            />
            <label
              htmlFor="qmaxValue"
              className="absolute text-base text-black
                duration-300 transform -translate-y-3 
                scale-90 top-4 z-10 origin-[0] start-16
                hover:cursor-pointer 
                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-3 rtl:peer-focus:translate-x-12 rtl:peer-focus:left-auto"
            >
              Qmax <span className="text-base text-gray-500">[ml/s]</span>
            </label>
          </div>
          {(clinicalData.qmaxValue || clinicalData.qmaxDate) && (
            <div className='bg-gray-bg p-t-2 px-4 pt-10 rounded-b-4xl rounded-t-md mt-[-32px] z-10 slideDownDiv'>
            <div className='relative text-white'>
              <label className="text-white font-light absolute top-1 left-2">Data de recolha da amostra</label>
              <input type="date" name="qmaxDate" onChange={handleChange} max={today} placeholder='dd/mm/yyyy'
                className="date-input-white block w-full h-13.5 px-2 pb-1 pt-5 bg-transparent rounded-2xl text-sm font-light
                hover:border-0 hover:text-white hover:cursor-pointer
                focus:outline-none focus:border-0"
              />
            </div>
          </div>
          )}

          <div className="mt-2 relative z-20">
            <ResidueIcon className="w-12 h-12 p-3.5 absolute mt-0.75 ml-1 rounded-full" style={{ backgroundColor: "#B4D5FF" }} />
            <input type="number" id="residueValue" name="residueValue" min="0" max="500" onChange={handleChange} value={clinicalData.residueValue} placeholder=''
              className="block rounded-full pl-16 pr-4 pb-1 pt-5 w-full h-13.5 
              text-sm text-gray-mid-dark 
              bg-cream-input
              appearance-none 
              focus:outline-none focus:bg-cream-focus peer"
            />
            <label
              htmlFor="residueValue"
              className="absolute text-base text-black
                duration-300 transform -translate-y-3 
                scale-90 top-4 z-10 origin-[0] start-16
                hover:cursor-pointer 
                peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-3 rtl:peer-focus:translate-x-12 rtl:peer-focus:left-auto"
            >
              Resíduo pós-miccional <span className="text-base text-gray-500">[ml]</span>
            </label>
          </div>
          {(clinicalData.residueValue || clinicalData.residueDate) && (
            <div className='bg-gray-bg p-t-2 px-4 pt-10 rounded-b-4xl rounded-t-md mt-[-32px] z-10 slideDownDiv'>
              <div className='relative text-white'>
                <label className="text-white font-light absolute top-1 left-2">Data de recolha da amostra</label>
                <input type="date" name="residueDate" onChange={handleChange} max={today} placeholder='dd/mm/yyyy'
                  className="date-input-white block w-full h-13.5 px-2 pb-1 pt-5 bg-transparent rounded-2xl text-sm font-light
                  hover:border-0 hover:text-white hover:cursor-pointer
                  focus:outline-none focus:border-0"
                />
              </div>
            </div>
          )}

        </div>

        <div className="max-h-9/10 h-[80%] bg-gradient-clinicalData absolute top-0 left-0 right-0 "></div>
        <div className="flex justify-end mt-4">
          <button className={"flex items-center justify-center w-full h-12 rounded-full border border-cyan-uro bg-cyan-uro transition-colors duration-300 ease-in-out hover:bg-black hover:border-black hover:text-white group"}>
            <Plus className="plus-icon w-3 h-3 absolute mt-0.5 left-[21%] transition-all duration-300 ease-in-out group-hover:invert"/>
            Adicionar ao historial médico
          </button>
        </div>
      </form>
    </div>
  );
}

export default ClinicalData;

ClinicalData.propTypes = {
  patientId: PropTypes.string.isRequired,
  birthdate: PropTypes.string.isRequired,
  showAlertWithMessage: PropTypes.func.isRequired
};