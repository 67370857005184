import React, { useState, useEffect, useRef } from 'react';
import axios from '../axios';
import logo from '../svgs/urolab-logo 1.svg';
import search from '../svgs/search.svg';
import '../styles/homePage.css';
import Drawer from '../components/drawer';
import PatientPopup from './patient';
import Navbar from '../components/navbar';
import LogoutPopup from '../components/logoutPopup';
import Alert from '../components/alert';
import {ReactComponent as Plus} from '../svgs/plus.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const HomePage = () => {
    const [patients, setPatients] = useState([]);
    const [showDrawer, setShowDrawer] = useState(false);
    const [updatePatients, setUpdatePatients] = useState(false);
    const [updateData, setUpdateData] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { logoutAuth } = useAuth();

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedPatientId, setSelectedPatientId] = useState(null);
    const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState(false);

    const tableWrapperRef = useRef(null);

    const showAlertWithMessage = (message, error) => {
        setAlertMessage(message);
        setIsError(error);
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 3000);
    };

    useEffect(() => {
        if (location.state) {
            setEmail(location.state.email);
        }
    }, [location.state]);

    const handleRowClick = (patientId) => {
        setSelectedPatientId(patientId);
        setIsPopupOpen(true);
    };

    useEffect(() => {
        axios.get('/patients')
            .then(response => {
                setPatients(response.data);
            })
            .catch(error => {
                console.error('Error fetching users:', error);
            });
    }, [updatePatients, updateData]);

    const closeDrawer = () => {
        setShowDrawer(false);
        setUpdatePatients(!updatePatients);
    };

    const drawer = () => {
        setShowDrawer(!showDrawer);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-4);
        return `${day}-${month}-${year}`;
    };

    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, ''); 
    
        let countryCode = '+351';
        if (cleaned.startsWith('351')) {
            countryCode = '+351';
        } else if (cleaned.startsWith('00351')) {
            countryCode = '+351';
        }
    
        const numberWithoutCountryCode = cleaned.replace(/^351|00351/, '');
        const areaCode = numberWithoutCountryCode.slice(0, 3);
        const subscriberNumber = numberWithoutCountryCode.slice(3, 6);
        const remainder = numberWithoutCountryCode.slice(6);
    
        return `${countryCode} ${areaCode} ${subscriberNumber} ${remainder}`;
    };
    

    const handleSearchChange = (event) => {
        setSearchInput(event.target.value);
    };

    const filteredPatients = patients
    .filter((patient) => {
        const searchTerm = searchInput.toLowerCase();
        return (
            patient.name.toLowerCase().startsWith(searchTerm) ||
            patient.phoneNumber.startsWith(searchTerm) ||
            formatDate(patient.birthdate).startsWith(searchTerm)
        );
    })
    .sort((a, b) => a.name.localeCompare(b.name));


    const logout = () => {
        axios.post('/logout')
            .then(() => {
                navigate('/');
                localStorage.removeItem('authData');
                logoutAuth();
            })
            .catch(error => {
                console.error('Error logging out:', error);
            });
    };

    useEffect(() => {
        const handleScroll = () => {
            const tableWrapper = tableWrapperRef.current;
            const nameHeader = tableWrapper.querySelector('th:first-child');
            const nameHeaderBottom = nameHeader.getBoundingClientRect().bottom;

            const tbody = tableWrapper.querySelector('tbody');

            tbody.querySelectorAll('tr').forEach((row) => {
                row.querySelectorAll('td').forEach((cell) => {
                    const cellTop = cell.getBoundingClientRect().top;
                    if (cellTop < nameHeaderBottom + 20) { 
                        cell.style.transition = 'opacity 0.3s ease';
                        cell.style.opacity = '0';
                    } else {
                        cell.style.transition = 'opacity 0.3s ease';
                        cell.style.opacity = '1';
                    }
                });
            });
        };

        const tableWrapper = tableWrapperRef.current;
        tableWrapper.addEventListener('scroll', handleScroll);

        return () => {
            tableWrapper.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="flex items-center justify-center min-h-screen bg-gradient-home">
            <Alert showAlert={showAlert} alertMessage={alertMessage} isError={isError} />
            <img src={logo} alt="logo" className="logo absolute left-10"/>
            <Navbar onLogout={() => setIsLogoutPopupOpen(true)} showAlertWithMessage={showAlertWithMessage} email={email} />
            <div className="w-full">
                <h1 className="text-5xl mt-24 ml-10 font-medium">Pacientes</h1>
                <div className="flex justify-center mt-6">
                    <div className="w-full ml-10 mr-10">
                        <div className="table-wrapper" ref={tableWrapperRef}>
                            <table className="w-full">
                                <thead>
                                    <tr>
                                        <th colSpan="5" className="pl-2 pr-2 py-2">
                                            <div className="flex justify-between">
                                                <div className="relative flex-grow">
                                                    <input
                                                        type="text"
                                                        id="searchInput"
                                                        value={searchInput}
                                                        onChange={handleSearchChange}
                                                        placeholder="Nome/ Nº Telemóvel/ Data de Nascimento"
                                                        className="search-bar rounded-3xl pl-10 py-3 w-full font-normal"
                                                    />
                                                    <img
                                                        src={search}
                                                        alt="search"
                                                        className="search-icon absolute left-3 top-3"
                                                    />
                                                </div>
                                                <button
                                                    className="button-color text-black font-normal py-2 px-5 rounded-3xl w-80 ml-2 py-3 transition-colors duration-300 ease-in-out hover:bg-black hover:text-white group"
                                                    onClick={drawer}
                                                >
                                                    <Plus className="plus-icon w-3 h-3 absolute mt-2 ml-14 transition-all duration-300 ease-in-out group-hover:invert"/>
                                                    Adicionar Paciente
                                                </button>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th className="px-4 pl-8 py-2 text-left text-white font-normal w-[22%]">Nome</th>
                                        <th className="px-4 py-2 text-left text-white font-normal w-[22%]">Email</th>
                                        <th className="px-4 py-2 text-left text-white font-normal w-[22%]">Nº Telemóvel</th>
                                        <th className="px-4 py-2 text-left text-white font-normal w-[22%]">Nº Utente</th>
                                        <th className="px-4 py-2 text-left text-white font-normal w-[12%]">Data Nasc.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredPatients.map((patient) => (
                                        <tr key={patient.patientId} onClick={() => handleRowClick(patient.patientId)} className='cursor-pointer hover:bg-row-hover'>
                                            <td className="px-4 pl-8 py-3 text-white text-left">{patient.name}</td>
                                            <td className="px-4 py-3 text-white text-left">{patient.email}</td>
                                            <td className="px-4 py-3 text-white text-left">{formatPhoneNumber(patient.phoneNumber)}</td>
                                            <td className="px-4 py-3 text-white text-left">{patient.healthUserId !== 0 ? patient.healthUserId : 'S/ Nº Utente'}</td>
                                            <td className="px-4 py-3 pr-8 text-white text-left">{formatDate(patient.birthdate)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {isPopupOpen && (
                                <PatientPopup
                                    patientId={selectedPatientId}
                                    onClose={() => {
                                        setIsPopupOpen(false);
                                        setUpdateData(!updateData);
                                    }}
                                    showAlertWithMessage={showAlertWithMessage}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Drawer
                isOpen={showDrawer}
                onClose={() => { closeDrawer() }}
                showAlertWithMessage={showAlertWithMessage}
            />
            {isLogoutPopupOpen && (
                <LogoutPopup 
                    isOpen={isLogoutPopupOpen} 
                    onClose={() => setIsLogoutPopupOpen(false)} 
                    onLogout={() => logout()} 
                />
            )}
        </div>
    );
};

export default HomePage;
