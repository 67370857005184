import React, { createContext, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

const AuthContext = createContext();

const TOKEN_EXPIRATION_TIME = 24 * 60 * 60 * 1000; // 24 hours 

const getStoredAuthData = () => {
  const authData = localStorage.getItem('authData');
  if (authData) {
    return JSON.parse(authData);
  }
  return null;
};

const storeAuthData = (token) => {
  const authData = {
    token,
    createdAt: Date.now(),
  };
  localStorage.setItem('authData', JSON.stringify(authData));
};

const removeAuthData = () => {
  localStorage.removeItem('authData');
};

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(() => getStoredAuthData());

  useEffect(() => {
    if (authData) {
      const { createdAt } = authData;
      const now = Date.now();

      if (now - createdAt > TOKEN_EXPIRATION_TIME) {
        removeAuthData();
        setAuthData(null);
      } else {
        storeAuthData(authData.token); 
      }
    } else {
      removeAuthData();
    }
  }, [authData]);

  const login = (userToken) => {
    const now = Date.now();
    const newAuthData = {
      token: userToken,
      createdAt: now,
    };
    setAuthData(newAuthData);
  };

  const logout = () => {
    setAuthData(null);
  };

  const isAuthenticated = authData && (Date.now() - authData.createdAt <= TOKEN_EXPIRATION_TIME);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
