import React from 'react';
import { ReactComponent as Check } from '../svgs/notificationcheck.svg';
import { ReactComponent as BG } from '../svgs/notificationbg.svg';
import { ReactComponent as Error } from '../svgs/notificationerror.svg';
import PropTypes from 'prop-types';

const Alert = ({ showAlert, alertMessage, isError }) => {
    if (!showAlert) return null;

    return (
        <div className={`fixed top-2 right-5 bg-notification-bg text-white py-4 px-4 rounded-3xl z-50`}>
            {isError ? (
                <Error className="w-12 h-12 inline-block mr-2"/>
            ) : (
                <>
                    <BG className="w-12 h-12 inline-block mr-2"/>
                    <Check className="w-8 h-8 absolute top-[26%] left-[8.5%]"/>
                </>
            )}
            {alertMessage}
        </div>
    );
};

Alert.propTypes = {
    showAlert: PropTypes.bool.isRequired,
    alertMessage: PropTypes.string.isRequired,
    isError: PropTypes.bool.isRequired
};

export default Alert;
