import React from 'react';
import Logo from '../svgs/urolab-logo.svg';
import '../styles/loading.css';



export const Loading = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gradient-custom">
      <div className="w-1/2 text-center">
        <img src={Logo} alt="Urolab Logo" className="mx-auto my-auto w-2/6" />
        <div className="mt-3">
          <div className="w-2/5 mx-auto bg-gray-300 rounded-full border border-gray-400 border-opacity-25">
            <div className="h-2 bg-white rounded-full overflow-hidden " style={{ animation: 'progress 2s ease-in-out forwards' }}>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;