import React from 'react';
import Logo from '../svgs/urolab-logo 1.svg';
import Emoji from '../svgs/mask.svg';
import { Link } from 'react-router-dom';
import '../styles/error.css';

export const Error = () => {
  return (
    <div className="relative h-screen bg-gradient-custom flex flex-col items-center justify-center">
        <div className="absolute top-8 left-8 w-18 h-18">
            <img src={Logo} alt="Urolab Logo" />
        </div>
        <div className="flex md:flex-row md:items-center md:space-y-0 md:space-x-32">
            <div className="text-center md:text-right max-w-40 flex-grow">
                <p className="text-9xl text-black">Erro 404</p>
            </div>
            <div className="relative bg-color p-4 bradius flex flex-col justify-between items-start text-white max-w-xs flex-grow">
                <div className="absolute top-4 right-4 w-16 h-16 ">
                    <img src={Emoji} alt="Emoji" />
                </div>

                <div className="flex flex-col justify-center items-center h-full">
                    <div className="text-left min-w-96">
                        <p className="text-4xl mt-16 max-w-60">Página não encontrada</p>
                        <p className="text-base text-gray-200 mb-4 mt-2 max-w-48">Sugerimos que regresse à Página Principal</p>
                    </div>
                </div>
                <div className="flex justify-end">
                    <Link
                        className="mt-4 inline-block text-base text-gray-400 bg-white bradius px-8 py-4 text-center"
                        to="/homePage"
                    >
                        Página Principal
                    </Link>
                </div>
            </div>


        </div>

    </div>
  );
};

export default Error;
