import React from 'react';

export const passwordStatus = ({ status = 'default', message }) => {
  const getStatusClasses = (status) => {
    switch (status) {
      case 'correct':
        return 'bg-green-100 text-green-700';
      case 'error':
        return 'bg-red-100 text-red-700';
      case 'default':
        return 'bg-gray-100 text-gray-700';
      default:
        return 'bg-gray-100 text-gray-700';
    }
  };

  return (
    <div className={`flex items-center p-1 px-2 text-xs rounded-full ${getStatusClasses(status)}`}>
      <svg className="w-3 h-3 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
      </svg>
      <span>{message}</span>
    </div>
  );
};
