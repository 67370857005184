import { React } from 'react';
import { Html, Head, Body, Container, Heading, Text, Button, Img } from '@react-email/components';

const LoginEmail = ({ email, password }) => (
  <Html>
    <Head />
    <Body style={{
      backgroundImage: `url(https://urolab.s3.eu-north-1.amazonaws.com/email.png), linear-gradient(to bottom left, white, white,  rgba(152, 236, 255, 0.3), rgba(152, 236, 255, 0.5))`,
      backgroundPosition: 'calc(100% + 150px) center, bottom left',
      backgroundRepeat: 'no-repeat, no-repeat',
      backgroundSize: 'contain, 50% 50%',
      height: '100vh',
      width: '100%',
      display: 'flex',
    }}>
      <Container style={{ width: '100%', height:'100%', marginLeft: '5%'}}>
          <div style={{ textAlign: 'center' }}>
            <Img src="https://urolab.s3.eu-north-1.amazonaws.com/urolab-logo.png" alt="Urolab Logo" style={{width:'250px', marginLeft:'22.55%'}} />
            <Heading style={{ fontSize: '58px', fontFamily:'Outfit, -apple-system, BlinkMacSystemFont', fontWeight:'500', whiteSpace: 'nowrap' }}>Bem vindo/a!</Heading>
            <Text style={{fontSize: '20px', marginTop:'-20px' , marginBottom: '10vh', fontFamily:'Outfit, -apple-system, BlinkMacSystemFont', fontWeight:'400' ,color:'#424242' }}>
              Aceda à plataforma de gestão de pessoas do UROLAB através do seguinte link.
            </Text>
            <Button href={`http://localhost:3000/?email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`} style={{ width: '60%', height: '30px', backgroundColor: '#98ECFF', color: '#000000', padding: '20px', borderRadius: '100px', fontSize: '21px', fontFamily:'Outfit, -apple-system, BlinkMacSystemFont'}}>
              Log in
            </Button>
          </div>
      </Container>
    </Body>
  </Html>
);

export default LoginEmail;

