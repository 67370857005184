import React, { useEffect, useRef, useState, useCallback } from 'react';
import axios from '../axios';
import PropTypes from 'prop-types';
import '../styles/drawer.css';
import X from "../svgs/X.svg";
import Check from '../svgs/Check.svg';
import { ReactComponent as MailIcon } from '../svgs/mail.svg';
import { ReactComponent as ProfileIcon } from '../svgs/profile.svg';
import { ReactComponent as Dropdown } from '../svgs/dropdown.svg';
import { ReactComponent as UserType } from '../svgs/usertype.svg';

const UserDrawer = ({ isOpen, onClose, userId, showAlertWithMessage }) => {
    const drawerRef = useRef(null);
    const [localName, setLocalName] = useState('');
    const [localEmail, setLocalEmail] = useState('');
    const [localType, setLocalType] = useState('');
    const [isNameFocused, setIsNameFocused] = useState(false);
    const [isEmailFocused, setIsEmailFocused] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const getUser = useCallback(() => {
        axios.get(`users/${userId}`)
          .then((response) => {
            setLocalName(response.data.name);
            setLocalEmail(response.data.email);
            setLocalType(response.data.isAdmin ? "Administrador" : "Médico");
          })
          .catch(error => {
            console.error('Error getting user:', error);
          });
      }, [userId]);
    
      useEffect(() => {
        if (isOpen) {
          getUser();
        }
      }, [isOpen, getUser]);

    const editUser = () => {
        const type = localType === "Médico" ? false : true;

        axios.put(`users/${userId}`, { 
            name: localName,
            email: localEmail,
            isAdmin: type
        })
            .then(() => {
                onClose();
                showAlertWithMessage('Utilizador editado com sucesso!', false);
            })
            .catch(error => {
                console.error('Error editing user:', error);
                showAlertWithMessage('Erro ao editar utilizador.', true);
            });
    };
          

    const handleSubmit = (event) => {
        event.preventDefault();
        editUser(localName, localEmail, localType);
    };

    useEffect(() => {
        if (!isOpen) {
            setLocalName('');
            setLocalEmail('');
            setLocalType('');
            setIsMenuOpen(false);
        }
    }, [isOpen]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (drawerRef.current && !drawerRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mouseup', handleClickOutside);

        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, [onClose]);

    const handleNameFocus = () => {
        setIsNameFocused(true);
    };
    const handleNameBlur = () => {
        setIsNameFocused(false);
    };

    const handleEmailFocus = () => {
        setIsEmailFocused(true);
    };
    const handleEmailBlur = () => {
        setIsEmailFocused(false);
    };
    const handleClick = (event) => {
        event.preventDefault();
        setIsMenuOpen(!isMenuOpen);
    };

    return (  
        <div className={`drawer ${isOpen ? 'open' : ''}`}>
            <div ref={drawerRef} className="drawer-content">
                <h2 className="drawer-header text-3xl pl-2">Editar Utilizador</h2>
                <div className="close-button transition-colors duration-300 ease-in-out hover:bg-black" onClick={onClose}>
                    <img src={X} alt="Fechar" className='w-3 h-3' />
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="mt-48 mb-2 relative ml-2 mr-2">
                        <ProfileIcon className="w-12 h-12 p-2 absolute mt-0.75 ml-1 rounded-full bg-white" />
                        <input id="name" type="text" name="name" value={localName} onChange={(e)=>{setLocalName(e.target.value)}} 
                            onFocus={handleNameFocus} onBlur={handleNameBlur}
                            className="block rounded-full pl-16 pr-4 pb-4 pt-5 w-full h-13.5 
                            text-sm text-gray-mid-dark 
                            bg-cream-input
                            appearance-none 
                            focus:outline-none focus:bg-dark-gray peer"
                        />
                        <label
                            htmlFor="name"
                            className={`absolute text-xl text-black
                                duration-300 transform -translate-y-3.5 
                                scale-75 top-7 z-10 origin-[0] start-16
                                ${isNameFocused || localName ? '-translate-y-8 scale-75' : 'scale-75 -translate-y-4'}`}
                        >
                            Nome
                        </label>
                    </div>
                    <div className="mb-2 relative ml-2 mr-2">
                        <MailIcon className="w-12 h-12 p-2.5 absolute mt-0.75 ml-1 rounded-full bg-white" />
                        <input id="email" type="email" name="email" value={localEmail} onChange={(e)=>{setLocalEmail(e.target.value)}}
                            onFocus={handleEmailFocus} onBlur={handleEmailBlur}
                            className="block rounded-full pl-16 pr-4 pb-4 pt-5 w-full h-13.5 
                            text-sm text-gray-mid-dark 
                            bg-cream-input
                            appearance-none 
                            focus:outline-none focus:bg-dark-gray peer"
                        />
                        <label
                            htmlFor="email"
                            className={`absolute text-xl text-black
                                duration-300 transform -translate-y-3.5 
                                scale-75 top-7 z-10 origin-[0] start-16
                                ${isEmailFocused || localEmail ? '-translate-y-8 scale-75' : 'scale-75 -translate-y-4'}`}
                        >
                            Email
                        </label>
                    </div>
                    <div className="mb-2 relative ml-2 mr-2">
                        <div onClick={()=>{setIsMenuOpen(!isMenuOpen)}} className={`block rounded-full w-full h-13.5 
                            text-sm text-gray-mid-dark 
                            ${isMenuOpen ? `bg-dark-gray peer`: 'bg-cream-input'}`} >
                            <UserType className="w-12 h-12 p-2 absolute mt-0.75 ml-1 rounded-full bg-white" />
                            {localType && (
                                <p className='absolute top-5 left-16'>{localType}</p>
                            )}
                            <button onClick={handleClick}>
                                <Dropdown className="w-6 h-6 p-1 absolute ml-120 mt-0.75 " />
                            </button>
                        </div>
                        {isMenuOpen && (
                            <div className="absolute top-full mt-2 right-0 bg-white p-4 rounded-3xl z-10 text-white black-trans shadow font-light backdrop-filter backdrop-blur-sm w-56">
                                <div onClick={()=>{setLocalType("Médico"); setIsMenuOpen(false)}} className='w-full h-10 rounded-full hover:bg-dark-hover hover:opacity-80 cursor-pointer'>
                                    <button value="Médico" className="absolute top-6 left-6" onClick={(e) => {e.preventDefault(); setLocalType(e.target.value); setIsMenuOpen(false)}}>
                                        Médico
                                    </button>
                                </div>
                                <div onClick={()=>{setLocalType("Administrador"); setIsMenuOpen(false)}} className='w-full h-10 rounded-full hover:bg-dark-hover hover:opacity-80 cursor-pointer'>
                                    <button value="Administrador" className="absolute top-16 left-6" onClick={(e) => {e.preventDefault(); setLocalType(e.target.value); setIsMenuOpen(false)}}>
                                        Administrador
                                    </button>
                                </div>
                            </div>
                        )}
                        <label
                            htmlFor="type"
                            onClick={(e)=>{e.preventDefault(); setIsMenuOpen(!isMenuOpen)}}
                            className={`absolute text-xl text-black
                                duration-300 transform -translate-y-3.5 
                                scale-75 top-7 z-10 origin-[0] start-16
                                ${localType ? '-translate-y-8 scale-75' : 'scale-75 -translate-y-4'}`}
                        >
                            Tipo de Utilizador
                        </label>
                    </div>
                    <div className="flex justify-end">
                        <button type="submit" className="absolute add-button text-whit de px-4 py-2 rounded-full flex items-center transition-colors duration-300 ease-in-out hover:bg-black group">
                            <img src={Check} alt="Adicionar" className='w-4 h-4 group-hover:invert' />
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

UserDrawer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
    showAlertWithMessage: PropTypes.func.isRequired
  };

export default UserDrawer;
